import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
  testId: string;
}

const SearchIcon = ({ className, onClick, testId }: Props) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;
  
  return (
    <div>
      { !isExpTheme ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="35" height="33" viewBox="0 0 35 33" fill="none" className={className} onClick={onClick} data-testid={testId}>
          <rect width="35" height="33" rx="2" fill="#191B1E"/>
          <path d="M16.1742 21.2752C19.6887 21.2752 22.5378 18.3203 22.5378 14.6752C22.5378 11.0301 19.6887 8.0752 16.1742 8.0752C12.6596 8.0752 9.81055 11.0301 9.81055 14.6752C9.81055 18.3203 12.6596 21.2752 16.1742 21.2752Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M24.9243 24.3006L20.6685 19.3369" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="25.5s" viewBox="0 0 37 26" fill="none" className={className} onClick={onClick} data-testid={testId}>
          <g clipPath="url(#clip0_1244_9996)">
            <path d="M17.0573 15.1042H16.2261L15.9316 14.8201C16.9626 13.6207 17.5833 12.0636 17.5833 10.3698C17.5833 6.59281 14.5218 3.53125 10.7448 3.53125C6.96781 3.53125 3.90625 6.59281 3.90625 10.3698C3.90625 14.1468 6.96781 17.2083 10.7448 17.2083C12.4386 17.2083 13.9957 16.5876 15.1951 15.5566L15.4792 15.8511V16.6823L20.7396 21.9322L22.3072 20.3646L17.0573 15.1042ZM10.7448 15.1042C8.1251 15.1042 6.01042 12.9895 6.01042 10.3698C6.01042 7.7501 8.1251 5.63542 10.7448 5.63542C13.3645 5.63542 15.4792 7.7501 15.4792 10.3698C15.4792 12.9895 13.3645 15.1042 10.7448 15.1042Z" fill="#0C0F24"/>
          </g>
          <defs>
            <clipPath id="clip0_1244_9996">
              <rect width="22.25" height="22.25" fill="white" transform="translate(0.75 0.375)"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </div>
  );
};

export default SearchIcon;
