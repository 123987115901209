import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const WarningIcon = ({ className, onClick }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M15.36 0C6.87 0 0 6.87 0 15.36c0 8.49 6.87 15.36 15.36 15.36 8.49 0 15.36-6.87 15.36-15.36C30.72 6.87 23.85 0 15.36 0zm0 28.32c-7.164 0-12.96-5.797-12.96-12.96C2.4 8.196 8.197 2.4 15.36 2.4c7.164 0 12.96 5.797 12.96 12.96 0 7.164-5.797 12.96-12.96 12.96z"></path>
          <path d="M15.36 7.733a1.2 1.2 0 00-1.2 1.2v7.727a1.2 1.2 0 102.4 0V8.933a1.2 1.2 0 00-1.2-1.2z"></path>
          <circle cx="15.36" cy="20.95" r="1.62"></circle>
        </g>
      </g>
    </svg>
  );
};

export default WarningIcon;
