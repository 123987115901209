import React, { MouseEvent } from 'react';

interface LandIconProps {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const LandIcon = ({ className, onClick }: LandIconProps) => {
  return (
    <svg className={className} onClick={onClick} width="11" height="16" viewBox="0 0 11 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.2627 6.38069C10.2627 9.12454 8.17783 11.2859 5.68136 11.2859C3.1849 11.2859 1.1 9.12454 1.1 6.38069C1.1 3.63683 3.1849 1.47549 5.68136 1.47549C8.17783 1.47549 10.2627 3.63683 10.2627 6.38069Z" stroke="black" stroke-width="1.2"/>
      <path d="M4.0625 7.35205L5.68168 8.97123V15.1241" stroke="black" stroke-width="1.2"/>
      <path d="M7.62465 5.73291L5.68164 8.97126" stroke="black" stroke-width="1.2"/>
    </svg>
  );
};

export default LandIcon;
