import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const CondoIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="14" height="13" viewBox="0 0 14 13" fill="none">
      <path d="M4 3V4" stroke="#191B1E"/>
      <path d="M6 3V4" stroke="#191B1E"/>
      <path d="M4 5V6" stroke="#191B1E"/>
      <path d="M6 5V6" stroke="#191B1E"/>
      <path d="M4 7V8" stroke="#191B1E"/>
      <path d="M6 7V8" stroke="#191B1E"/>
      <path d="M2 11.5V1H8V11.5" stroke="#191B1E"/>
      <path d="M0 12L13.5 12" stroke="#191B1E"/>
      <path d="M8.5 3.5H11.5V11.5" stroke="#191B1E"/>
    </svg>
  );
};

export default CondoIcon;
