import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const AttachedIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="17" height="10" viewBox="0 0 17 10" fill="none">
      <path d="M3.77734 9.42857V6H5.66623V10" stroke="black"/>
      <path d="M7.55469 9.42857V6H9.44358V10" stroke="black"/>
      <path d="M11.334 9.42857V6H13.2229V10" stroke="black"/>
      <path d="M1.88867 10V1H15.1109V10" stroke="black"/>
      <path d="M0 9.5H17" stroke="black"/>
    </svg>
  );
};

export default AttachedIcon;
