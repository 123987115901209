import React from 'react';
import { buildClassName } from 'utils/build-class-name';
import useLocationSearch, { searchProvider } from 'hooks/use-location-search';
import useOutsideClickHandler from 'hooks/use-outside-click-handler';
import InputWithIcons from 'components/control/input-with-icons';
import SuggestedLocationDropdown from 'components/suggested-location-dropdown';
import SearchIcon from 'components/icon/search-icon-with-bg';
import CloseIcon from 'components/icon/close-icon';
import styles from './style.module.scss';
import { useFeaturesContext } from 'contexts';
import { mapPageIds } from 'constants/test-constants';

import type SearchPrediction from 'data/search-predictions';
import { SEARCH_OPTION_AGENTS, SEARCH_OPTION_BUY, SearchOptions } from 'themes/themeConfig';

interface Props {
  locationQuery: string;
  isLocationDropdownActive: boolean;
  isAreaSearch?: boolean;
  setLocationQuery: (query: string) => void;
  setIsLocationDropdownActive: (isActive: boolean) => void;
  setActivePrediction?: (searchPrediction: SearchPrediction) => void;
  onClick: (searchPrediction: SearchPrediction) => void;
  className?: string;
  chosenArea?: string;
  setChosenArea?: (area: string) => void;
  placeholder?: string;
  searchOption?: SearchOptions;
  onSearchIconClick?: (searchPredictions: SearchPrediction[]) => void;
}

export default function LocationSearch({ locationQuery, isLocationDropdownActive, isAreaSearch, setLocationQuery, setIsLocationDropdownActive, setActivePrediction = () => {}, onClick, className, chosenArea, setChosenArea, placeholder, searchOption = SEARCH_OPTION_BUY, onSearchIconClick }: Props) {
  const { features } = useFeaturesContext();
  const { isLoadingSearchPredictions, searchPredictions, searchAgentPredictions, isTyping } = useLocationSearch(
    locationQuery,
    features.useNewSearch,
    features.useUsListings,
    features.useGoogleSearch ? searchProvider.GOOGLE : searchProvider.APPLE,
    searchOption
  );
  const isAgentSearch = searchOption === SEARCH_OPTION_AGENTS;

  return (
    <div className={buildClassName(styles.component, className)} ref={useOutsideClickHandler(() => { isAreaSearch && setIsLocationDropdownActive(false); })}>
      <SuggestedLocationDropdown
        isLoading={isLoadingSearchPredictions}
        searchPredictions={searchPredictions}
        setActivePrediction={setActivePrediction}
        onClick={onClick}
        isActive={isLocationDropdownActive}
        isAreaSearch={isAreaSearch}
        isAdvancedSearch={true}
        locationQuery={locationQuery}
        isTyping={isTyping}
        hideSearchPanel={() => setIsLocationDropdownActive(false)}
        disablePopularSearches={isAgentSearch || isAreaSearch}
        searchAgentPredictions={searchAgentPredictions}
      />
      <InputWithIcons
        RightIcon={SearchIcon}
        MiddleIcon={CloseIcon}
        value={locationQuery}
        placeholder={placeholder}
        testId={mapPageIds.mapPageSearchbar}
        testIdMiddleIcon="mapPageSearchbarClear"
        padded={isAgentSearch}
        handleMiddleIconClick={() => {
          setLocationQuery('');
          setChosenArea && setChosenArea('');
        }}
        handleRightIconClick={() => onSearchIconClick(searchPredictions)}
        onValueChange={value => {
          setLocationQuery(value);
        }}
        chosenArea={chosenArea}
        onClick={() => setIsLocationDropdownActive(true)}
      />
    </div>
  );
}
