import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const ClockIcon = ({ className, onClick }: Props) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;
  
  return ( !isExpTheme ?
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" className={className} onClick={onClick}>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000" fillRule="nonzero">
          <path d="M17.92 0C8.016 0 0 8.015 0 17.92c0 9.904 8.015 17.92 17.92 17.92 9.904 0 17.92-8.015 17.92-17.92C35.84 8.016 27.825 0 17.92 0zm11.636 27.576l-.945-.944a1.4 1.4 0 10-1.98 1.98l.945.944a14.976 14.976 0 01-8.256 3.42V31.64a1.4 1.4 0 10-2.8 0v1.336a14.976 14.976 0 01-8.256-3.42l.945-.945a1.4 1.4 0 10-1.98-1.98l-.945.945a14.976 14.976 0 01-3.42-8.256H4.2a1.4 1.4 0 100-2.8H2.864a14.976 14.976 0 013.42-8.256l.945.945a1.4 1.4 0 101.98-1.98l-.945-.945a14.977 14.977 0 018.256-3.42V4.2a1.4 1.4 0 102.8 0V2.864a14.976 14.976 0 018.256 3.42l-.944.945a1.4 1.4 0 101.98 1.98l.944-.945a14.976 14.976 0 013.42 8.256H31.64a1.4 1.4 0 100 2.8h1.336a14.976 14.976 0 01-3.42 8.256zM20.89 18.91a1.4 1.4 0 11-1.98 1.98l-1.98-1.98a1.4 1.4 0 01-.41-.99V12.6a1.4 1.4 0 112.8 0v4.74l1.57 1.57z"></path>
        </g>
      </g>
    </svg>
    :
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 18 20" fill="none">
      <path d="M4 9H6V11H4V9ZM18 4V18C18 19.1 17.1 20 16 20H2C0.89 20 0 19.1 0 18L0.00999999 4C0.00999999 2.9 0.89 2 2 2H3V0H5V2H13V0H15V2H16C17.1 2 18 2.9 18 4ZM2 6H16V4H2V6ZM16 18V8H2V18H16ZM12 11H14V9H12V11ZM8 11H10V9H8V11Z" fill="#0C0F24"/>
    </svg>

  );
};

export default ClockIcon;
