import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const NotificationIcon = ({ className, onClick }: Props) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <>
      { !isExpTheme ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="31" viewBox="0 0 23 31" className={className} onClick={onClick}>
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="#000" fillRule="nonzero">
              <path d="M22.6 25.273l-2.917-4.4v-9.92c0-4.29-3.082-7.853-7.083-8.454V1.2a1.2 1.2 0 10-2.4 0v1.299c-4 .601-7.083 4.163-7.083 8.454v9.92L.2 25.274a1.2 1.2 0 001 1.863h5.692c.52 2.059 2.344 3.584 4.508 3.584 2.165 0 3.989-1.525 4.508-3.584H21.6a1.2 1.2 0 001-1.863zM11.4 28.32c-.83 0-1.558-.476-1.95-1.184h3.9c-.392.708-1.12 1.184-1.95 1.184zm-7.964-3.584l1.88-2.837a1.2 1.2 0 00.2-.663V10.953c0-3.388 2.64-6.144 5.884-6.144s5.883 2.756 5.883 6.144v10.283c0 .235.07.466.2.663l1.882 2.837H3.435z"></path>
            </g>
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 20 20" className={className} onClick={onClick}>
          <g clipPath="url(#clip0_1244_10032)">
            <path d="M9.99992 18.8333C10.9166 18.8333 11.6666 18.0833 11.6666 17.1666H8.33325C8.33325 18.0833 9.08325 18.8333 9.99992 18.8333ZM14.9999 13.8333V9.66658C14.9999 7.10825 13.6416 4.96659 11.2499 4.39992V3.83325C11.2499 3.14159 10.6916 2.58325 9.99992 2.58325C9.30825 2.58325 8.74992 3.14159 8.74992 3.83325V4.39992C6.36659 4.96659 4.99992 7.09992 4.99992 9.66658V13.8333L3.33325 15.4999V16.3333H16.6666V15.4999L14.9999 13.8333ZM13.3333 14.6666H6.66659V9.66658C6.66659 7.59992 7.92492 5.91659 9.99992 5.91659C12.0749 5.91659 13.3333 7.59992 13.3333 9.66658V14.6666Z" fill="#0C0F24"/>
          </g>
          <defs>
            <clipPath id="clip0_1244_10032">
              <rect width="20" height="20" fill="#0C0F24" transform="translate(0 0.5)"/>
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
};

export default NotificationIcon;
