import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const HouseIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="16" height="13" viewBox="0 0 16 13" fill="none">
      <path d="M1 7L8 1L15 7" stroke="#191B1E"/>
      <path d="M3 5.5V12H13V5.5" stroke="#191B1E"/>
      <path d="M6.5 12V8.5H9.5V12" stroke="#191B1E"/>
      <path d="M10.5 3V1.5H12V4.5" stroke="#191B1E"/>
    </svg>
  );
};

export default HouseIcon;
