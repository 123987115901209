import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const DetachedIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
      <path d="M6 1L1 3.65517V12H11V3.65517L6 1Z" stroke="#191B1E"/>
      <path d="M4.5 11.5V8.5H8V12" stroke="black"/>
    </svg>
  );
};

export default DetachedIcon;
