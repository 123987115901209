import React, { MouseEvent } from 'react';

interface FarmIconProps {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const FarmIcon = ({ className, onClick }: FarmIconProps) => {
  return (
    <svg className={className} onClick={onClick} width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.863281 9.5V2L2.86328 0.5L4.36328 1.625L5.86328 0.5L7.36328 1.625L8.86328 0.5L10.8633 2V9.5H0.863281ZM1.86328 8.5H3.86328V2.5L2.86328 1.75L1.86328 2.5V8.5ZM4.86328 8.5H6.86328V2.5L5.86328 1.75L4.86328 2.5V8.5ZM7.86328 8.5H9.86328V2.5L8.86328 1.75L7.86328 2.5V8.5Z" fill="black"/>
    </svg>
  );
};

export default FarmIcon;