import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const TownhouseIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="15" height="13" viewBox="0 0 15 13" fill="none">
      <path d="M4.25 1L1 3.65517V12H7.5V3.65517L4.25 1Z" stroke="#191B1E"/>
      <path d="M5.17969 12.0003V8.96582H7.9654" stroke="#191B1E"/>
      <path d="M3 5.08496V6.08496" stroke="#191B1E"/>
      <path d="M5 5.08496V6.08496" stroke="#191B1E"/>
      <path d="M10 5.08496V6.08496" stroke="#191B1E"/>
      <path d="M12 5.08496V6.08496" stroke="#191B1E"/>
      <path d="M10.75 1L14 3.65517V12H7.5V3.65517L10.75 1Z" stroke="#191B1E"/>
      <path d="M9.82031 12.0003V8.96582H7.0346" stroke="#191B1E"/>
    </svg>
  );
};

export default TownhouseIcon;
