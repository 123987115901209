import React, { MouseEvent } from 'react';
import { useThemeContext } from 'contexts';
import { ThemeNames } from 'types/themes';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent<Element>) => void;
}

const NibIcon = ({ className, onClick }: Props) => {
  const { themeName } = useThemeContext();
  const isExpTheme = themeName && themeName !== ThemeNames.ZOOCASA;

  return (
    <>
      { !isExpTheme ? (
        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" className={className} onClick={onClick}>
          <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="#181B1E" transform="translate(-89 -158)">
              <g transform="translate(38 138)">
                <path d="M55.5 20L60 26 51 26z" transform="rotate(-180 55.5 23)"></path>
              </g>
            </g>
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 2.75 20 20" className={className} onClick={onClick}>
          <path d="M9.64683 11.3291L10 11.6815L10.3532 11.3291L13.8246 7.86517L14.2929 8.33345L10 12.6263L5.70711 8.33345L6.17539 7.86517L9.64683 11.3291Z" fill="#0C0F24" stroke="#0C0F24"/>
        </svg>
      )}
    </>
  );
};

export default NibIcon;
