import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const SemiDetachedIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
      <path d="M4 1L1 3.41379V11H7V3.41379L4 1Z" stroke="#191B1E"/>
      <path d="M10 1L7 3.41379V11H13V3.41379L10 1Z" stroke="#191B1E"/>
      <path d="M3 10.5714V8H5V11" stroke="black"/>
      <path d="M9 10.5714V8H11V11" stroke="black"/>
    </svg>
  );
};

export default SemiDetachedIcon;
