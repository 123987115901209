import React, { MouseEvent } from 'react';

interface Props {
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const OpenHouseIcon = ({ className, onClick }: Props) => {
  return (
    <svg className={className} onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="18" height="14" viewBox="0 0 18 14" fill="none">
      <path d="M1 7.80952L8.94445 1L16.8889 7.80952" stroke="#191B1E"/>
      <path d="M3.26953 6.10742V13.4844H14.6187V6.10742" stroke="#191B1E"/>
      <path d="M6.44 8.176C6.44 7.584 6.54667 7.072 6.76 6.64C6.97867 6.208 7.29067 5.87467 7.696 5.64C8.10133 5.4 8.584 5.28 9.144 5.28C9.69867 5.28 10.1787 5.4 10.584 5.64C10.9893 5.87467 11.3013 6.208 11.52 6.64C11.744 7.072 11.856 7.58133 11.856 8.168C11.856 8.76 11.744 9.27467 11.52 9.712C11.3013 10.144 10.9893 10.48 10.584 10.72C10.1787 10.96 9.69867 11.08 9.144 11.08C8.584 11.08 8.10133 10.96 7.696 10.72C7.296 10.48 6.98667 10.144 6.768 9.712C6.54933 9.27467 6.44 8.76267 6.44 8.176ZM7.52 8.176C7.52 8.81067 7.656 9.30667 7.928 9.664C8.2 10.0213 8.60533 10.2 9.144 10.2C9.66667 10.2 10.0693 10.0213 10.352 9.664C10.6347 9.30667 10.776 8.81067 10.776 8.176C10.776 7.536 10.6347 7.04 10.352 6.688C10.0747 6.336 9.672 6.16 9.144 6.16C8.60533 6.16 8.2 6.336 7.928 6.688C7.656 7.04 7.52 7.536 7.52 8.176Z" fill="#191B1E"/>
    </svg>
  );
};

export default OpenHouseIcon;
