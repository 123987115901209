import React, { ReactElement } from 'react';
import { camelize } from '@zoocasa/node-kit/strings/camelize';
import CheckboxInput from '@zoocasa/node-kit/components/controls/checkbox-input';
import { buildClassName } from 'utils/build-class-name';
import styles from './style.module.scss';

import type { CheckboxInputProps } from '@zoocasa/node-kit/components/controls/checkbox-input';

type Props = CheckboxInputProps & {
  label: string | ReactElement;
  testId?: string;
  Icon?: any;
  isFullWidth?: boolean; // if true, takes entire roll and label is on left
};

export default function Toggle({ label, testId, Icon, name, value, className, onClick, isFullWidth, ...inputProps }: Props) {
  const id = 'input-' + camelize(name || label.toString());

  return (
    <div className={buildClassName(styles.component, className, isFullWidth && styles['component-full-width'])}>
      {isFullWidth &&
        <div className={styles['label-left']}>
          {Icon && <Icon className={styles['icon']} />}
          <label htmlFor={id}>{label}</label>
        </div>
      }
      <div className={styles.switch}>
        <CheckboxInput id={id} {...inputProps} name={name} value={value} />
        <span onClick={onClick} data-testid={testId}></span>
      </div>
      {!isFullWidth && <label htmlFor={id}>{label}</label>}
    </div>
  );
}
